<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="account">{{ account.name }}</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <v-alert type="warning" v-if="isAccountBillingSummaryReady && (!accountBillingSummary || accountBillingSummary.error)">
                        Billing data not available at this time.
                        <a href="#" class="text-alert" @click="navigateToBilling">Manage billing</a>
                    </v-alert> -->
                    <v-card class="my-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Account settings</v-app-bar-title>
                            <v-spacer/>
                        </v-app-bar>
                        <v-card-text>

                            <p class="text-overline mb-0 mt-4">Acount Name</p>
                            <p class="mb-0 pb-0 mt-0">
                            <EditableText label="Account Name" :value="account.name" @input="saveAccountName" dense/>
                            </p>

                            <!-- TODO: API TOKENS HERE !!! -->
                            <!-- <p>To obtain API client tokens, please contact customer support. Self-service coming soon!</p> -->

                            <!-- <template v-if="isPermitSystemAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="red--text">
                                Danger zone
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="font-size: 0.8em;">
                            <p class="mt-4 mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete' }">How to delete this account and related data</router-link>
                            </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
a.text-alert, a.text-alert:visited {
    color: #ffffff;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { ROLE_SYSTEM_ADMIN } from '@/sdk/constants';
// import UserList from '@/components/account-dashboard/UserList.vue';
import EditableText from '@/components/EditableText.vue';

export default {
    components: {
        // UserList,
        EditableText,
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
    },
    watch: {
        focus() {
            // TODO: reload data when user returns to this tab
        },
    },
    methods: {
        async saveAccountAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveAccountAttr: true });
                const response = await this.$client.account(this.account.id).self.edit({ id: this.account.id }, { [name]: value });
                console.log(`saveAccountAttr: response ${JSON.stringify(response)}`);
                if (!response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit account' });
                    return false;
                }
                // this.$set(this.account, name, value);
                // this.$store.commit('account', { ...this.account, [name]: value });
                await this.$store.dispatch('loadAccount', { accountId: this.account.id });
                return true;
            } catch (err) {
                console.error(`failed to edit account attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit account' });
                return false;
            } finally {
                this.$store.commit('loading', { saveAccountAttr: false });
            }
        },
        async saveAccountName(value) {
            await this.saveAccountAttr('name', value);
        },
    },
    mounted() {
    },
};
</script>
